<template>
  <div class="dabox">
    <ul>
      <li>
        <div class="juhefu">
          <p class="xtitle">3#釜趋势</p>
          <div>
            <p
              class="bianliang1"
              @click="
                toCompon(2, 'TE04', 'DEVICE3S', 'TE04_FYF', '反应釜温度')
              "
              @dblclick="
                Cclick(infoList.DEVICE3S.TE04, 'TE04', 'DEVICE3S', 'TE04_FYF')
              "
            >
              {{ infoList.DEVICE3S.TE04 }} ℃
            </p>
            <p
              class="bianliang2"
              @click="
                toCompon(2, 'PT01', 'DEVICE3S', 'PT01_FYF', '反应釜压力')
              "
              @dblclick="
                Cclick(infoList.DEVICE3S.PT01, 'PT01', 'DEVICE3S', 'PT01_FYF')
              "
            >
              {{ infoList.DEVICE3S.PT01 }} MPa
            </p>
          </div>
          <div>
            <p class="bianliang3">压力降</p>
            <p
              class="bianliang4"
              @click="toCompon(2, 'PT04', 'DEVICE3S', 'PT04_FYF', '压力降')"
              @dblclick="
                Cclick(infoList.DEVICE3S.PT04, 'PT04', 'DEVICE3S', 'PT04_FYF')
              "
            >
              {{ infoList.DEVICE3S.PT04 }} MPa
            </p>
          </div>
          <div>
            <p class="bianliang5">反应时间</p>
            <p
              class="bianliang6"
              @click="
                toCompon(2, 'AI02', 'DEVICE3S', 'AI02_FYF', '聚合釜反应时间')
              "
              @dblclick="
                Cclick(infoList.DEVICE3S.AI02, 'AI02', 'DEVICE3S', 'AI02_FYF')
              "
            >
              {{ infoList.DEVICE3S.AI02 }} 分
            </p>
          </div>
          <div class="famen2">
            <img :src="infoList.DEVICE3S.II01 | tyFifter" alt="" />
          </div>
          <p class="title">C-3#聚合釜</p>
        </div>
        <!-- 文字标识 -->
        <div>
          <p class="wenzi tubi">涂壁</p>
          <p class="wenzi gaoyachongxishui">高压冲洗水</p>
          <p class="wenzi zhongzhiji">终止剂</p>
          <p class="wenzi zhongtuzhushui">中途注水</p>
          <p class="wenzi shedingzhi">釜压高限保护设定值</p>
          <p class="wenzi huanchongji">缓冲剂</p>
          <p class="wenzi fensanji">分散剂</p>
          <p class="wenzi yinfaji">引发剂</p>
          <p class="wenzi zhoufengshui">轴封水</p>
          <p class="wenzi zhengqi">蒸汽</p>
          <p class="wenzi huishou">回收</p>
          <p class="wenzi chouzhenkong">抽真空</p>
          <p class="wenzi chongxishui">冲洗水</p>
          <p class="wenzi vcm">VCM</p>
          <p class="wenzi chongxishui2">冲洗水</p>
          <p class="wenzi ruanshui">软水</p>
          <p class="wenzi chuliao">出料</p>
        </div>
        <!-- 阀门 -->
        <div>
          <div class="famen1 jiange1">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange2">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange3">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange4">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange5">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange6">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange7">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange8">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange9">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange10">
            <img :src="infoList.DEVICE3S.VF01 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange11">
            <img :src="infoList.DEVICE3S.VF02 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange12">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange13">
            <img :src="6 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange14">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange15">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange16">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange17">
            <img :src="6 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange18">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange19">
            <img :src="6 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange20">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange21">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange22">
            <img :src="2 | typeFifter" alt="" />
          </div>
           <div
            class="caozuo1"
            :style="{
              background:
                infoList.DEVICE3M__p__XK05 && infoList.DEVICE3M__p__XK05.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__XK05
                ? toDetail(1, 'XK05', 'DEVICE3M__p__XK05', '', 'XK05运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo2"
            :style="{
              background:
                infoList.DEVICE3M__p__XK01 && infoList.DEVICE3M__p__XK01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__XK01
                ? toDetail(1, 'XK01', 'DEVICE3M__p__XK01', '', 'XK01运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo3"
            :style="{
              background:
                infoList.DEVICE3M__p__MAN01 &&
                infoList.DEVICE3M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE3M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A
          </div>
          <div
            class="caozuo4"
             :style="{
                background: infoList.DEVICE3M__p__RSF01&&infoList.DEVICE3M__p__RSF01.PV==0 ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.DEVICE3M__p__RSF01
                  ? toDetail(
                      3,
                      'RSF01',
                      'DEVICE3M__p__RSF01',
                      '',
                      'RSF01跟踪开关'
                    )
                  : ''
              "
          >
            R
          </div>
          <div
            class="caozuo5"
            :style="{
              background:
                infoList.DEVICE3M__p__XK06 && infoList.DEVICE3M__p__XK06.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__XK06
                ? toDetail(1, 'XK06', 'DEVICE3M__p__XK06', '', 'XK06运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo6"
            :style="{
              background:
                infoList.DEVICE3M__p__XK02 && infoList.DEVICE3M__p__XK02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__XK02
                ? toDetail(1, 'XK02', 'DEVICE3M__p__XK02', '', 'XK02运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo7"
            :style="{
              background:
                infoList.DEVICE3M__p__MAN02 &&
                infoList.DEVICE3M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE3M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE3M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A
          </div>
        </div>
        <!-- 数值 -->
        <div>
          <p
            class="shuzhi1 daxiao"
            @click="toCompon(2, 'VF05', 'DEVICE3S', 'VF05_FYF', '中途注水反馈')"
            @dblclick="
              Cclick(infoList.DEVICE3S.VF05, 'VF05', 'DEVICE3S', 'VF05_FYF')
            "
          >
            {{ infoList.DEVICE3S.VF05 }}%
          </p>
          <p
            class="shuzhi2 daxiao"
            @click="toCompon(2, 'FT05', 'DEVICE3S', 'FT05_FYF', '中途注水流量')"
            @dblclick="
              Cclick(infoList.DEVICE3S.FT05, 'FT05', 'DEVICE3S', 'FT05_FYF')
            "
          >
            {{ infoList.DEVICE3S.FT05 }} Kg/h
          </p>
          <p
            class="shuzhi3 daxiao"
            @click="toCompon(2, 'FT06', 'DEVICE3S', 'FT06_FYF', '中途注水总量')"
            @dblclick="
              Cclick(infoList.DEVICE3S.FT06, 'FT06', 'DEVICE3S', 'FT06_FYF')
            "
          >
            {{ infoList.DEVICE3S.FT06 }} Kg
          </p>
          <p
            class="shuzhi4 daxiao"
            @click="toCompon(2, 'SP10', 'DEVICE3S', 'SP10_FYF', '备用设定值')"
            @dblclick="
              Cclick(infoList.DEVICE3S.SP10, 'SP10', 'DEVICE3S', 'SP10_FYF')
            "
          >
            <span>{{ infoList.DEVICE3S.SP10 }} </span>MPa
          </p>
          <p
            class="shuzhi5 daxiao"
            @click="toCompon(2, 'TE01', 'PUB', 'TE01', '循环水温度')"
            @dblclick="Cclick(infoList.PUB.TE01, 'TE01', 'PUB', 'TE01')"
          >
            {{ infoList.PUB.TE01 }} ℃
          </p>
          <p
            class="shuzhi6 daxiao"
            @click="toCompon(2, 'PT01', 'PUB', 'PT01', '循环水压力')"
            @dblclick="Cclick(infoList.PUB.PT01, 'PT01', 'PUB', 'PT01')"
          >
            {{ infoList.PUB.PT01 }} MPa
          </p>
          <p
            class="shuzhi7 daxiao"
            @click="toCompon(2, 'AN44', 'DEVICE3V', 'AN44_FYF', '总用水流量')"
            @dblclick="
              Cclick(infoList.DEVICE3V.AN44, 'AN44', 'DEVICE3V', 'AN44_FYF')
            "
          >
            {{ infoList.DEVICE3V.AN44 }} m³ /h
          </p>
          <p
            class="shuzhi8 daxiao"
            @click="toCompon(2, 'AN43', 'DEVICE3V', 'AN43_FYF', '总用水累积')"
            @dblclick="
              Cclick(infoList.DEVICE3V.AN43, 'AN43', 'DEVICE3V', 'AN43_FYF')
            "
          >
            {{ infoList.DEVICE3V.AN43 }}
          </p>
          <p
            class="shuzhi9 daxiao"
            @click="toCompon(2, 'FT01', 'DEVICE3S', 'FT01_FYF', '冷却水流量')"
            @dblclick="
              Cclick(infoList.DEVICE3S.FT01, 'FT01', 'DEVICE3S', 'FT01_FYF')
            "
          >
            {{ infoList.DEVICE3S.FT01 }} m³/h
          </p>
          <p
            class="shuzhi10 daxiao"
            @click="toCompon(2, 'AN41', 'DEVICE3V', 'AN41_FYF', '夹套水累积')"
            @dblclick="
              Cclick(infoList.DEVICE3V.AN41, 'AN41', 'DEVICE3V', 'AN41_FYF')
            "
          >
            {{ infoList.DEVICE3V.AN41 }}
          </p>
          <p
            class="shuzhi11 daxiao"
            @click="toCompon(2, 'VF01', 'DEVICE3S', 'VF01_FYF', '冷却水阀反馈')"
            @dblclick="
              Cclick(infoList.DEVICE3S.VF01, 'VF01', 'DEVICE3S', 'VF01_FYF')
            "
          >
            {{ infoList.DEVICE3S.VF01 }} %
          </p>
          <p
            class="shuzhi12 daxiao"
            @click="toCompon(2, 'FT02', 'DEVICE3S', 'FT02_FYF', '内冷水流量')"
            @dblclick="
              Cclick(infoList.DEVICE3S.FT02, 'FT02', 'DEVICE3S', 'FT02_FYF')
            "
          >
            {{ infoList.DEVICE3S.FT02 }} m³/h
          </p>
          <p
            class="shuzhi13 daxiao"
            @click="toCompon(2, 'AN42', 'DEVICE3V', 'AN42_FYF', '挡板水累积')"
            @dblclick="
              Cclick(infoList.DEVICE3V.AN42, 'AN42', 'DEVICE3V', 'AN42_FYF')
            "
          >
            {{ infoList.DEVICE3V.AN42 }}
          </p>
          <p
            class="shuzhi14 daxiao"
            @click="toCompon(2, 'VF02', 'DEVICE3S', 'VF02_FYF', '内冷水阀反馈')"
            @dblclick="
              Cclick(infoList.DEVICE3S.VF02, 'VF02', 'DEVICE3S', 'VF02_FYF')
            "
          >
            {{ infoList.DEVICE3S.VF02 }} %
          </p>
          <p
            class="shuzhi15 daxiao"
            @click="toCompon(2, 'FT04', 'DEVICE3S', 'FT04_FYF', '轴封水流量')"
            @dblclick="
              Cclick(infoList.DEVICE3S.FT04, 'FT04', 'DEVICE3S', 'FT04_FYF')
            "
          >
            {{ infoList.DEVICE3S.FT04 }} m³/h
          </p>
          <p
            class="shuzhi16 daxiao"
            @click="toCompon(2, 'VF04', 'DEVICE3S', 'VF04_FYF', '轴封水阀反馈')"
            @dblclick="
              Cclick(infoList.DEVICE3S.VF04, 'VF04', 'DEVICE3S', 'VF04_FYF')
            "
          >
            {{ infoList.DEVICE3S.VF04 }} %
          </p>
          <p
            class="shuzhi17 daxiao"
            @click="
              toCompon(2, 'VF06', 'DEVICE3S', 'VF06_FYF', 'VCM进料阀反馈')
            "
            @dblclick="
              Cclick(infoList.DEVICE3S.VF06, 'VF06', 'DEVICE3S', 'VF06_FYF')
            "
          >
            {{ infoList.DEVICE3S.VF06 }} %
          </p>
          <p
            class="shuzhi18 daxiao"
            @click="
              toCompon(2, 'TE01', 'DEVICE3S', 'TE01_FYF', '冷却水出口温度')
            "
            @dblclick="
              Cclick(infoList.DEVICE3S.TE01, 'TE01', 'DEVICE3S', 'TE01_FYF')
            "
          >
            {{ infoList.DEVICE3S.TE01 }} ℃
          </p>
          <p
            class="shuzhi19 daxiao"
            @click="
              toCompon(2, 'TE02', 'DEVICE3S', 'TE02_FYF', '内冷水出口温度')
            "
            @dblclick="
              Cclick(infoList.DEVICE3S.TE02, 'TE02', 'DEVICE3S', 'TE02_FYF')
            "
          >
            {{ infoList.DEVICE3S.TE02 }} ℃
          </p>
          <p
            class="shuzhi20 daxiao"
            @click="toCompon(2, 'II01', 'DEVICE3S', 'II01_FYF', '搅拌机电流')"
            @dblclick="
              Cclick(infoList.DEVICE3S.II01, 'II01', 'DEVICE3S', 'II01_FYF')
            "
          >
            {{ infoList.DEVICE3S.II01 }} A
          </p>
          <p class="jiantou1">|</p>
          <p class="jiantou2">|</p>
          <p class="jiantou3">|</p>
          <p class="jiantou4">|</p>
        </div>
      </li>
      <li>
        <div class="juhefu">
          <p class="xtitle">4#釜趋势</p>
          <div>
            <p
              class="bianliang1"
              @click="toCompon(2, 'TE04', 'DEVICE4S', 'TE04_FYF', '反应釜温度')"
              @dblclick="
                Cclick(infoList.DEVICE4S.TE04, 'TE04', 'DEVICE4S', 'TE04_FYF')
              "
            >
              {{ infoList.DEVICE4S.TE04 }} ℃
            </p>
            <p
              class="bianliang2"
              @click="toCompon(2, 'PT01', 'DEVICE4S', 'PT01_FYF', '反应釜压力')"
              @dblclick="
                Cclick(infoList.DEVICE4S.PT01, 'PT01', 'DEVICE4S', 'PT01_FYF')
              "
            >
              {{ infoList.DEVICE4S.PT01 }} MPa
            </p>
          </div>
          <div>
            <p class="bianliang3">压力降</p>
            <p
              class="bianliang4"
              @click="toCompon(2, 'PT04', 'DEVICE4S', 'PT04_FYF', '压力降')"
              @dblclick="
                Cclick(infoList.DEVICE4S.PT04, 'PT04', 'DEVICE4S', 'PT04_FYF')
              "
            >
              {{ infoList.DEVICE4S.PT04 }} MPa
            </p>
          </div>
          <div>
            <p class="bianliang5">反应时间</p>
            <p
              class="bianliang6"
              @click="
                toCompon(2, 'AI02', 'DEVICE4S', 'AI02_FYF', '聚合釜反应时间')
              "
              @dblclick="
                Cclick(infoList.DEVICE4S.AI02, 'AI02', 'DEVICE4S', 'AI02_FYF')
              "
            >
              {{ infoList.DEVICE4S.AI02 }} 分
            </p>
          </div>
          <div class="famen2">
            <img :src="infoList.DEVICE4S.II01 | tyFifter" alt="" />
          </div>
          <p class="title">C-4#聚合釜</p>
        </div>
        <!-- 文字标识 -->
        <div>
          <p class="wenzi tubi">涂壁</p>
          <p class="wenzi gaoyachongxishui">高压冲洗水</p>
          <p class="wenzi zhongzhiji">终止剂</p>
          <p class="wenzi zhongtuzhushui">中途注水</p>
          <p class="wenzi shedingzhi">釜压高限保护设定值</p>
          <p class="wenzi huanchongji">缓冲剂</p>
          <p class="wenzi fensanji">分散剂</p>
          <p class="wenzi yinfaji">引发剂</p>
          <p class="wenzi zhoufengshui">轴封水</p>
          <p class="wenzi zhengqi">蒸汽</p>
          <p class="wenzi huishou">回收</p>
          <p class="wenzi chouzhenkong">抽真空</p>
          <p class="wenzi chongxishui">冲洗水</p>
          <p class="wenzi vcm">VCM</p>
          <p class="wenzi chongxishui2">冲洗水</p>
          <p class="wenzi ruanshui">软水</p>
          <p class="wenzi chuliao">出料</p>
        </div>
        <!-- 阀门 -->
        <div>
          <div class="famen1 jiange1">
            <img :src="4 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange2">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange3">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange4">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange5">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange6">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange7">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange8">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange9">
            <img :src="2 | typeFifter" alt="" />
          </div>
           <div class="famen1 jiange10">
            <img :src="infoList.DEVICE4S.VF01 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange11">
            <img :src="infoList.DEVICE4S.VF02 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange12">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange13">
            <img :src="6 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange14">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange15">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange16">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange17">
            <img :src="6 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange18">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange19">
            <img :src="6 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange20">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange21">
            <img :src="2 | typeFifter" alt="" />
          </div>
          <div class="famen1 jiange22">
            <img :src="2 | typeFifter" alt="" />
          </div>
                   <div
            class="caozuo1"
            :style="{
              background:
                infoList.DEVICE4M__p__XK05 && infoList.DEVICE4M__p__XK05.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__XK05
                ? toDetail(1, 'XK05', 'DEVICE4M__p__XK05', '', 'XK05运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo2"
            :style="{
              background:
                infoList.DEVICE4M__p__XK01 && infoList.DEVICE4M__p__XK01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__XK01
                ? toDetail(1, 'XK01', 'DEVICE4M__p__XK01', '', 'XK01运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo3"
            :style="{
              background:
                infoList.DEVICE4M__p__MAN01 &&
                infoList.DEVICE4M__p__MAN01.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__MAN01
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE4M__p__MAN01',
                    'MAN01_RM_FYF',
                    '夹套水阀手操器'
                  )
                : ''
            "
          >
            A
          </div>
          <div
            class="caozuo4"
             :style="{
                background: infoList.DEVICE4M__p__RSF01&&infoList.DEVICE4M__p__RSF01.PV==0 ? '#2AFC30' : 'red',
              }"
              @click="
                infoList.DEVICE4M__p__RSF01
                  ? toDetail(
                      3,
                      'RSF01',
                      'DEVICE4M__p__RSF01',
                      '',
                      'RSF01跟踪开关'
                    )
                  : ''
              "
          >
            R
          </div>
          <div
            class="caozuo5"
            :style="{
              background:
                infoList.DEVICE4M__p__XK06 && infoList.DEVICE4M__p__XK06.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__XK06
                ? toDetail(1, 'XK06', 'DEVICE4M__p__XK06', '', 'XK06运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo6"
            :style="{
              background:
                infoList.DEVICE4M__p__XK02 && infoList.DEVICE4M__p__XK02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__XK02
                ? toDetail(1, 'XK02', 'DEVICE4M__p__XK02', '', 'XK02运行方式')
                : ''
            "
          >
            X
          </div>
          <div
            class="caozuo7"
            :style="{
              background:
                infoList.DEVICE4M__p__MAN02 &&
                infoList.DEVICE4M__p__MAN02.RM == 1
                  ? '#2AFC30'
                  : 'red',
            }"
            @click="
              infoList.DEVICE4M__p__MAN02
                ? toCompon(
                    0,
                    'AV',
                    'DEVICE4M__p__MAN02',
                    'MAN02_RM_FYF',
                    '内冷水阀手操器'
                  )
                : ''
            "
          >
            A
          </div>
        </div>
        <!-- 数值 -->
        <div>
          <p
            class="shuzhi1 daxiao"
            @click="toCompon(2, 'VF05', 'DEVICE4S', 'VF05_FYF', '中途注水反馈')"
            @dblclick="
              Cclick(infoList.DEVICE4S.VF05, 'VF05', 'DEVICE4S', 'VF05_FYF')
            "
          >
            {{ infoList.DEVICE4S.VF05 }}%
          </p>
          <p
            class="shuzhi2 daxiao"
            @click="toCompon(2, 'FT05', 'DEVICE4S', 'FT05_FYF', '中途注水流量')"
            @dblclick="
              Cclick(infoList.DEVICE4S.FT05, 'FT05', 'DEVICE4S', 'FT05_FYF')
            "
          >
            {{ infoList.DEVICE4S.FT05 }} Kg/h
          </p>
          <p
            class="shuzhi3 daxiao"
            @click="toCompon(2, 'FT06', 'DEVICE4S', 'FT06_FYF', '中途注水总量')"
            @dblclick="
              Cclick(infoList.DEVICE4S.FT06, 'FT06', 'DEVICE4S', 'FT06_FYF')
            "
          >
            {{ infoList.DEVICE4S.FT06 }} Kg
          </p>
          <p
            class="shuzhi4 daxiao"
            @click="toCompon(2, 'SP10', 'DEVICE4S', 'SP10_FYF', '备用设定值')"
            @dblclick="
              Cclick(infoList.DEVICE4S.SP10, 'SP10', 'DEVICE4S', 'SP10_FYF')
            "
          >
            <span>{{ infoList.DEVICE4S.SP10 }} </span>MPa
          </p>
          <p
            class="shuzhi5 daxiao"
            @click="toCompon(2, 'TE01', 'PUB', 'TE01', '循环水温度')"
            @dblclick="Cclick(infoList.PUB.TE01, 'TE01', 'PUB', 'TE01')"
          >
            {{ infoList.PUB.TE01 }} ℃
          </p>
          <p
            class="shuzhi6 daxiao"
            @click="toCompon(2, 'PT01', 'PUB', 'PT01', '循环水压力')"
            @dblclick="Cclick(infoList.PUB.PT01, 'PT01', 'PUB', 'PT01')"
          >
            {{ infoList.PUB.PT01 }} MPa
          </p>
          <p
            class="shuzhi7 daxiao"
            @click="toCompon(2, 'AN44', 'DEVICE4V', 'AN44_FYF', '总用水流量')"
            @dblclick="
              Cclick(infoList.DEVICE4V.AN44, 'AN44', 'DEVICE4V', 'AN44_FYF')
            "
          >
            {{ infoList.DEVICE4V.AN44 }} m³ /h
          </p>
          <p
            class="shuzhi8 daxiao"
            @click="toCompon(2, 'AN43', 'DEVICE4V', 'AN43_FYF', '总用水累积')"
            @dblclick="
              Cclick(infoList.DEVICE4V.AN43, 'AN43', 'DEVICE4V', 'AN43_FYF')
            "
          >
            {{ infoList.DEVICE4V.AN43 }}
          </p>
          <p
            class="shuzhi9 daxiao"
            @click="toCompon(2, 'FT01', 'DEVICE4S', 'FT01_FYF', '冷却水流量')"
            @dblclick="
              Cclick(infoList.DEVICE4S.FT01, 'FT01', 'DEVICE4S', 'FT01_FYF')
            "
          >
            {{ infoList.DEVICE4S.FT01 }} m³/h
          </p>
          <p
            class="shuzhi10 daxiao"
            @click="toCompon(2, 'AN41', 'DEVICE4V', 'AN41_FYF', '夹套水累积')"
            @dblclick="
              Cclick(infoList.DEVICE4V.AN41, 'AN41', 'DEVICE4V', 'AN41_FYF')
            "
          >
            {{ infoList.DEVICE4V.AN41 }}
          </p>
          <p
            class="shuzhi11 daxiao"
            @click="toCompon(2, 'VF01', 'DEVICE4S', 'VF01_FYF', '冷却水阀反馈')"
            @dblclick="
              Cclick(infoList.DEVICE4S.VF01, 'VF01', 'DEVICE4S', 'VF01_FYF')
            "
          >
            {{ infoList.DEVICE4S.VF01 }} %
          </p>
          <p
            class="shuzhi12 daxiao"
            @click="toCompon(2, 'FT02', 'DEVICE4S', 'FT02_FYF', '内冷水流量')"
            @dblclick="
              Cclick(infoList.DEVICE4S.FT02, 'FT02', 'DEVICE4S', 'FT02_FYF')
            "
          >
            {{ infoList.DEVICE4S.FT02 }} m³/h
          </p>
          <p
            class="shuzhi13 daxiao"
            @click="toCompon(2, 'AN42', 'DEVICE4V', 'AN42_FYF', '挡板水累积')"
            @dblclick="
              Cclick(infoList.DEVICE4V.AN42, 'AN42', 'DEVICE4V', 'AN42_FYF')
            "
          >
            {{ infoList.DEVICE4V.AN42 }}
          </p>
          <p
            class="shuzhi14 daxiao"
            @click="toCompon(2, 'VF02', 'DEVICE4S', 'VF02_FYF', '内冷水阀反馈')"
            @dblclick="
              Cclick(infoList.DEVICE4S.VF02, 'VF02', 'DEVICE4S', 'VF02_FYF')
            "
          >
            {{ infoList.DEVICE4S.VF02 }} %
          </p>
          <p
            class="shuzhi15 daxiao"
            @click="toCompon(2, 'FT04', 'DEVICE4S', 'FT04_FYF', '轴封水流量')"
            @dblclick="
              Cclick(infoList.DEVICE4S.FT04, 'FT04', 'DEVICE4S', 'FT04_FYF')
            "
          >
            {{ infoList.DEVICE4S.FT04 }} m³/h
          </p>
          <p
            class="shuzhi16 daxiao"
            @click="toCompon(2, 'VF04', 'DEVICE4S', 'VF04_FYF', '轴封水阀反馈')"
            @dblclick="
              Cclick(infoList.DEVICE4S.VF04, 'VF04', 'DEVICE4S', 'VF04_FYF')
            "
          >
            {{ infoList.DEVICE4S.VF04 }} %
          </p>
          <p
            class="shuzhi17 daxiao"
            @click="
              toCompon(2, 'VF06', 'DEVICE4S', 'VF06_FYF', 'VCM进料阀反馈')
            "
            @dblclick="
              Cclick(infoList.DEVICE4S.VF06, 'VF06', 'DEVICE4S', 'VF06_FYF')
            "
          >
            {{ infoList.DEVICE4S.VF06 }} %
          </p>
          <p
            class="shuzhi18 daxiao"
            @click="
              toCompon(2, 'TE01', 'DEVICE4S', 'TE01_FYF', '冷却水出口温度')
            "
            @dblclick="
              Cclick(infoList.DEVICE4S.TE01, 'TE01', 'DEVICE4S', 'TE01_FYF')
            "
          >
            {{ infoList.DEVICE4S.TE01 }} ℃
          </p>
          <p
            class="shuzhi19 daxiao"
            @click="
              toCompon(2, 'TE02', 'DEVICE4S', 'TE02_FYF', '内冷水出口温度')
            "
            @dblclick="
              Cclick(infoList.DEVICE4S.TE02, 'TE02', 'DEVICE4S', 'TE02_FYF')
            "
          >
            {{ infoList.DEVICE4S.TE02 }} ℃
          </p>
          <p
            class="shuzhi20 daxiao"
            @click="toCompon(2, 'II01', 'DEVICE4S', 'II01_FYF', '搅拌机电流')"
            @dblclick="
              Cclick(infoList.DEVICE4S.II01, 'II01', 'DEVICE4S', 'II01_FYF')
            "
          >
            {{ infoList.DEVICE4S.II01 }} A
          </p>
          <p class="jiantou1">|</p>
          <p class="jiantou2">|</p>
          <p class="jiantou3">|</p>
          <p class="jiantou4">|</p>
        </div>
      </li>

      <!-- <li></li> -->
    </ul>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    	<Manual :key="isIndex" v-if="isMshow" @sendStatus="isClose" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :titname="Manualtitname" 
			:infoList="infoList"></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "Jhindex",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    // 阀门红绿灯
    typeFifter: (val) => {
      if (val < 5) {
        return require("@/assets/images/截止阀.png");
      } else if (val >= 5) {
        return require("@/assets/images/截止阀2.png");
      }
    },
    //釜内红绿灯
    tyFifter: (val) => {
      if (val <= 10) {
        return require("@/assets/images/关闭.png");
      } else if (val > 10) {
        return require("@/assets/images/开始.png");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 100%;
  height: 117vh;
  padding: 4vw;
  ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    li {
      width: 38%;
      height: 70%;
      //   background-color: pink;
      background-image: url("~@/assets/images/管道线.png");
      background-size: 100% 100%;
      margin-left: 5vw;
      position: relative;
      .juhefu {
        width: 14vw;
        height: 37.3vh;
        background-image: url("~@/assets/images/釜趋势.png");
        background-size: 100% 100%;
        margin-left: 12.2vw;
        margin-top: 23vh;
        .xtitle {
          font-size: 0.7vw;
          margin-left: 5.4vw;
          margin-top: 3.5vh;
        }
        .bianliang1 {
          color: #357eeb;
          font-size: 0.7vw;
          margin-left: 2.4vw;
          margin-top: 7vh;
        }
        .bianliang2 {
          color: #357eeb;
          font-size: 0.7vw;
          margin-left: 2.4vw;
          margin-top: 9vh;
        }
        .bianliang3 {
          color: #000;
          font-size: 0.7vw;
          margin-left: 9.4vw;
          margin-top: 7vh;
        }
        .bianliang4 {
          color: #357eeb;
          font-size: 0.7vw;
          margin-left: 9.4vw;
          margin-top: 9vh;
        }
        .bianliang5 {
          color: #000;
          font-size: 0.7vw;
          margin-left: 9.4vw;
          margin-top: 12vh;
        }
        .bianliang6 {
          color: #357eeb;
          font-size: 0.7vw;
          margin-left: 9.4vw;
          margin-top: 14vh;
        }
        .famen2 {
          position: absolute;
          top: 16vw;
          left: 18.5vw;
          width: 1.5vw;
          height: 1vh;
        }
        .title {
          font-size: 1vw;
          color: #000;
          font-weight: 700;
          margin-top: 20vh;
          margin-left: 4.5vw;
        }
      }
      p {
        color: #fff;
        position: absolute;
      }
      .famen1 {
        position: absolute;
        width: 1.5vw;
        height: 1vh;
      }
      .tubi {
        top: -2.5vh;
        left: 0.5vw;
      }
      .gaoyachongxishui {
        top: 3.1vh;
        left: 0.5vw;
      }
      .zhongzhiji {
        top: 12.3vh;
        left: 0.5vw;
      }
      .zhongtuzhushui {
        top: 16.5vh;
        left: 0.5vw;
      }
      .shedingzhi {
        top: 24.5vh;
        left: -2vw;
      }
      .huanchongji {
        top: 59.7vh;
        left: -0.5vw;
      }
      .fensanji {
        top: 63vh;
        left: -0.5vw;
      }
      .yinfaji {
        top: 65vh;
        left: -0.5vw;
      }
      .zhoufengshui {
        top: 70vh;
        left: -0.5vw;
      }
      .zhengqi {
        top: -2.6vh;
        right: 0vw;
      }
      .huishou {
        top: 2.8vh;
        right: 0vw;
      }
      .chouzhenkong {
        top: 6.7vh;
        right: 0vw;
      }
      .chongxishui {
        top: 10.7vh;
        right: 0vw;
      }
      .vcm {
        top: 16.2vh;
        right: 0vw;
      }
      .chongxishui2 {
        top: 61vh;
        right: 0vw;
      }
      .ruanshui {
        top: 65vh;
        right: 0vw;
      }
      .chuliao {
        top: 70vh;
        right: 0vw;
      }

      .jiange1 {
        top: -3vh;
        left: 6.5vw;
        cursor: pointer;
      }
      .jiange2 {
        top: 3vh;
        left: 6.5vw;
        cursor: pointer;
      }
      .jiange3 {
        top: -3vh;
        left: 23.5vw;
        cursor: pointer;
      }
      .jiange4 {
        top: 2.4vh;
        left: 29.3vw;
        cursor: pointer;
      }
      .jiange5 {
        top: 9.8vh;
        left: 10.3vw;
        cursor: pointer;
      }
      .jiange6 {
        top: 9.8vh;
        left: 13.2vw;
        cursor: pointer;
      }
      .jiange7 {
        top: 12.1vh;
        left: 6.5vw;
        cursor: pointer;
      }
      .jiange8 {
        top: 16.2vh;
        left: 5.5vw;
        cursor: pointer;
      }
      .jiange9 {
        top: 16.2vh;
        left: 8.5vw;
        cursor: pointer;
      }
      .jiange10 {
        top: 33.2vh;
        left: 5.5vw;
        cursor: pointer;
      }
      .jiange11 {
        top: 53.5vh;
        left: 7.5vw;
        cursor: pointer;
      }
      .jiange12 {
        top: 60vh;
        left: 7.5vw;
        cursor: pointer;
      }
      .jiange13 {
        top: 66.8vh;
        left: 7.5vw;
        cursor: pointer;
      }
      .jiange14 {
        top: 66.8vh;
        left: 12.5vw;
        cursor: pointer;
      }
      .jiange15 {
        top: 6.5vh;
        left: 29.3vw;
        cursor: pointer;
      }
      .jiange16 {
        top: 10.4vh;
        left: 29.3vw;
        cursor: pointer;
      }
      .jiange17 {
        top: 7vh;
        left: 24.3vw;
        cursor: pointer;
        transform: rotate(90deg);
      }
      .jiange18 {
        top: 15.8vh;
        left: 28.3vw;
        cursor: pointer;
      }
      .jiange19 {
        top: 15.8vh;
        left: 31.3vw;
        cursor: pointer;
      }
      .jiange20 {
        top: 57.8vh;
        left: 25.3vw;
        cursor: pointer;
      }
      .jiange21 {
        top: 61.9vh;
        left: 27.5vw;
        cursor: pointer;
      }
      .jiange22 {
        top: 66.6vh;
        left: 30vw;
        cursor: pointer;
      }
      .caozuo1 {
        position: absolute;
        top: 13vw;
        left: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .caozuo2 {
        position: absolute;
        top: 14.3vw;
        left: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .caozuo3 {
        position: absolute;
        top: 14.3vw;
        left: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .caozuo4 {
        position: absolute;
        top: 21vw;
        left: 12vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .caozuo5 {
        position: absolute;
        top: 21vw;
        left: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .caozuo6 {
        position: absolute;
        top: 22.4vw;
        left: 12vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .caozuo7 {
        position: absolute;
        top: 22.4vw;
        left: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1vw;
        height: 1vw;
        color: #fff;
        cursor: pointer;
      }
      .shuzhi1 {
        top: 20vh;
        left: 5.4vw;
      }
      .jiantou1 {
        top: 19vh;
        left: 2.1vw;
        font-size: 0.5vw;
      }
      .jiantou2 {
        top: 36vh;
        left: 2.1vw;
        font-size: 0.5vw;
      }
      .jiantou3 {
        top: 54.2vh;
        left: 2.1vw;
        font-size: 0.5vw;
      }
      .jiantou4 {
        top: 68vh;
        left: 4.1vw;
        font-size: 0.5vw;
      }
      .shuzhi2 {
        top: 20.5vh;
        left: 1vw;
      }
      .shuzhi3 {
        top: 22vh;
        left: 1vw;
      }
      .shuzhi4 {
        top: 24.7vh;
        left: 6vw;
        span {
          color: #07f606;
        }
      }
      .shuzhi5 {
        top: 27vh;
        left: -4vw;
      }
      .shuzhi6 {
        top: 28.5vh;
        left: -4vw;
      }
      .shuzhi7 {
        top: 30vh;
        left: -4vw;
      }
      .shuzhi8 {
        top: 31.5vh;
        left: -4vw;
      }
      .shuzhi9 {
        top: 38vh;
        left: 1vw;
      }
      .shuzhi10 {
        top: 39.5vh;
        left: 1vw;
      }
      .shuzhi11 {
        top: 37vh;
        left: 5.4vw;
      }
      .shuzhi12 {
        top: 51vh;
        left: 1.3vw;
      }
      .shuzhi13 {
        top: 52.5vh;
        left: 1.3vw;
      }
      .shuzhi14 {
        top: 57vh;
        left: 7.5vw;
      }
      .shuzhi15 {
        top: 66vh;
        left: 2.5vw;
      }
      .shuzhi16 {
        top: 70.5vh;
        left: 7.5vw;
      }
      .shuzhi17 {
        top: 20vh;
        left: 31.4vw;
      }
      .shuzhi18 {
        top: 29vh;
        left: 26.6vw;
      }
      .shuzhi19 {
        top: 53.3vh;
        left: 26.8vw;
      }
      .shuzhi20 {
        top: 61vh;
        left: 18vw;
      }
      .daxiao {
        font-size: 0.7vw;
      }
      .wenzi {
        font-size: 0.8vw;
      }
    }
  }
}
</style>